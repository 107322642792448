<template>
  <no-ssr>
    <VueSlickCarousel
        dotsClass="slick-dots"
        @beforeChange="change"
        v-bind="settingsCarousel"
    >
      <product-item
          v-for="(p, index) in relatedProducts"
          :key="index"
          :product="p"
          style=""
      >
        ></product-item
      >
      <template #customPaging="page">
        <div
            class="circle-imatge"
            :class="{
          gris: page !== currentIndex,
          darrer: page === relatedProducts.length - 1,
        }"
        ></div>
      </template>
    </VueSlickCarousel>
  </no-ssr>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "../../../components/ProductItem";
import NoSSR from "vue-no-ssr";

export default {
  name: "ProductRelatedCarousel",
  components: {
    VueSlickCarousel,
    ProductItem,
    "no-ssr": NoSSR,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      settingsCarousel: {
        slidesToShow: 5,
        centerMode: true,
        infinite: true,
        dots: false,
        arrows: true,
        draggable: false,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
              arrows: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ],
      },
    };
  },
  computed: {
    relatedProducts() {
      return this.product.relatedProducts ? this.product.relatedProducts : [];
    },
  },
  methods: {
    change(oldSlideIndex, newSlideIndex) {
      this.currentIndex = newSlideIndex;
      this.$emit("change", newSlideIndex);
    },
  },
};
</script>

<style scoped>
img {
  max-height: 80%;
  max-width: 80%;
  width: auto;
  height: auto;
  margin: 10%;
}

.circle-imatge {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-radius: 60px;
  background-color: #ffbd59;
}

.circle-imatge.gris {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-radius: 60px;
  background-color: #dcdcdc;
}

.circle-imatge.gris.darrer {
  margin-right: 0px;
}
</style>
