<template>
  <div class="product-related pad-2" v-if="show">
    <div class="bb-container">
      <h3 class="product-related__title">Objetos relacionados</h3>
    </div>
    <div class="bb-container bb-container--575-no-padding">
      <div class="product-related__content">
        <product-related-carousel :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductRelatedCarousel from "./ProductRelatedCarousel";

export default {
  name: "ProductRelated",
  components: {
    ProductRelatedCarousel,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    show() {
      return this.product.relatedProducts?.length > 0;
    },
  },
};
</script>
